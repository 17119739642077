import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion'

import Lottie from "lottie-react";
import washerAnimation from 'assets/animations/washer_animation.json';
import cleanClothes from 'assets/animations/clean_clothes.json';
import goShopping from 'assets/animations/go_shopping.json';
import receiveClothes from 'assets/animations/receive_clothes.json';

import { getRequests } from 'store/request';
import Card from 'components/ui/Card';
import Icon from 'components/ui/Icon';

const LatestRequestCard = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.auth);
  const { list: requests } = useSelector(state => state.request);

  const notIncludedStatuses = [1, 2, 3, 14, 15, 16];

  const [loading, setLoading] = useState(!requests);
  const [maxState, setMaxState] = useState(false);

  const [title, setTitle] = useState(t('home.text03'));
  // const [description, setDescription] = useState(t('home.text04'));

  const [lottieConfig, setLottieConfig] = useState({
    animationData: washerAnimation,
    autoPlay: false,
  })

  useEffect(() => {
    if (user && !requests) {
      dispatch(getRequests(user.id))
        .then(() => setLoading(false));
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requests) {
      if (!user) {
        setMaxState(false);
        return;
      }
      const validRequests = requests.filter(r => !notIncludedStatuses.includes(r.state));

      const maxStateRequest = validRequests.reduce(
        (max, current) => (current.state > max.state ? current : max),
        validRequests[0]
      );

      if (maxStateRequest) setMaxState(maxStateRequest.state); else setMaxState(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, requests]);

  useEffect(() => {
    switch (maxState) {
      case 4:
        setTitle(t('home.requestReceived'));
        setLottieConfig({ animationData: receiveClothes, autoPlay: false });
        break;

      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        setTitle(t('home.text03'));
        setLottieConfig({ animationData: washerAnimation, autoPlay: false });
        break;

      case 12:
        setTitle(t('home.requestReady'));
        setLottieConfig({ animationData: cleanClothes, autoPlay: false });
        break;

      default:
        setTitle(t('home.noRequests'));
        setLottieConfig({ animationData: goShopping, autoPlay: true });
        break;

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxState]);

  return (
    <Card className='!px-4 !py-4 h-full'>
    <AnimatePresence mode="wait" initial={false}>
      {loading ? (
        <motion.div
          key="loading"
          className='flex flex-col h-full w-full items-center'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <Icon icon='line-md:loading-alt-loop' className='mt-4' />
          <div className="flex flex-col gap-2 justify-center mt-11">
            <motion.span
              className="text-xs font-semibold">
              {t('request.loading')}
            </motion.span>
            <motion.span className="text-xs text-secondary invisible">
              One moment..
            </motion.span>
          </div>
        </motion.div>
      ) : (
        <motion.div
          key="loaded"
          className='flex flex-col items-center'
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <Lottie
            className='h-20 w-20'
            {...lottieConfig}
          />
          <div className="flex flex-col gap-2 justify-center">
            <motion.span
              key={title}
              className="text-xs font-semibold">
              {title}
            </motion.span>
            <motion.span className="text-xs text-secondary">
              Pedido 000 0000
            </motion.span>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </Card>
  )
}

export default LatestRequestCard;